import React from 'react';
import logo from '../assets/icons/logo.png';
import fb from'../assets/icons/fb.svg';
import twitter from '../assets/icons/twitter.svg';
import youtube from '../assets/icons/youtube.svg';
import insta from '../assets/icons/insta.svg';
import translate from '../i18n/translate';

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='cover_outside'>
                    <div className='left_col'>
                        <a className='logo' href=''>
                            <img src={logo} alt='logo' />
                        </a>
                        <ul className='social'>
                            <li><a href=''><img src={fb} alt='social_1' /></a></li>
                            <li><a href=''><img src={twitter} alt='social_2' /></a></li>
                            <li><a href=''><img src={youtube} alt='social_3' /></a></li>
                            <li><a href=''><img src={insta} alt='social_4' /></a></li>
                        </ul>
                        <div className='copyright'>© 2018 Axiomatika</div>
                    </div>
                    <div className='menu_block'>
                        <ul>
                            <li>{translate('product')}</li>
                            <li><a href='#'>{translate('companyName')}</a></li>
                            <li><a href='https://axilink.site/' target='_blank' rel='noreferrer'>{translate('axiLink')}</a></li>
                            <li><a href='#'>{translate('axiDecision')}</a></li>
                            <li><a href='#'>{translate('tariffs')}</a></li>
                        </ul>
                    </div>
                    <div className='menu_block'>
                        <ul>
                            <li>{translate('about')}<span>{translate('axiomatika')}</span></li>
                            <li><a href='#'>{translate('company')}</a></li>
                            <li><a href='#'>{translate('jobs')}</a></li>
                        </ul>
                    </div>
                    <div className='menu_block'>
                        <ul>
                            <li>{translate('contacts')}</li>
                            <li><a href='#'>{translate('integrations')}</a></li>
                            <li><a href='#'>{translate('developers')}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import { LOCALES } from '../locales';

export default {
    [LOCALES.RUSSIAN]: {
        'headerTitle': 'Всё, что нужно для эффективной оценки заемщиков',
        'whatIs':'Что такое ',
        'axiLink': 'AxiLink',
        'axiDecision':'Система поддержки принятия решений AxiDecision',
        'axiDecisionDescMain': `Программное обеспечение «Система поддержки принятия решений AxiDecision» позволяет осуществлять процесс разработки,
         тестирования и управления стратегией принятия решения и бизнес-правилами.`,
        'axiDecisionDesc':'Функциональные характеристики ПО',
        'fn_check': `Создание и модификация логических проверок в рамках бизнес-процессов при оценке кредитоспособности, возможного мошенничества,
            валидации и андеррайтинга в банковских, страховых и иных финансовых институтах;`,
        'fn_gui': 'Поддержка графического интерфейса и предустановленных визуальных компонент системы для возможности настройки и модификации;',
        'fn_audit': 'Проведение аудита и протоколирование действий пользователя;',
        'fn_development': 'Возможность вести разработку несколькими пользователями;',
        'fn_permissions': 'Осуществление контроля доступа пользователей и разграничения физически сред разработки, тестирования и "боевой" эксплуатации;',
        'fn_customization': 'Возможность создавать собственные агрегаты, функции и сценарии обработки посредством адаптивного языка;',
        'fn_integrity': 'Контроль целостности сборки и сокращения доли ошибок реализации настроек системы посредством механизма check-in/check-out и версионирования компонент;',
        'fn_models': 'Доступность использования широкого спектра скоринговых моделей как интерпретируемых, так и не-/слабо- интерпретируемых в формате PMML.',
        'manageRequests': `Благодаря широким возможностям программное обеспечение «Система поддержки принятия решений AxiDecision» позволяет перенести всю логику
         принятия решений риск-менеджера в стратегию и заложить алгоритм, на основе которого осуществляется процесс обработки заявок.`,
        'advantages': 'Основные преимущества ПО "Система поддержки принятия решений AxiDecision"',
        'experience': `Успешный опыт нашей команды по внедрению аналогичных систем оценки кредитных заявок от вендоров FICO и
            Experian в розничном кредитовании для банков, МФО и страховых.`,
        'lowCost': '<b>Отсутствие</b> капительных <b>затрат</b>;',
        'lowCode': '<b>Low-Code</b> для сокращения времени включения сотрудников риск-департамента в процесс разработки стратегий оценки;',
        'presets': 'Наличие <b>преднастроенных, универсальных объектов</b> системы;',
        'fastIntegration': '<b>Высокая скорость</b> внедрения;',
        'supportCostReduction': '<b>Сокращение затрат</b> на поддержку решения;',
        'questionMark':'?',
        'month':'мес',
        'connectionCost':'Стоимость подключения',
        'connectionRequest':'Стоимость программного обеспечения рассчитывается индивидуально',
        'priceTitle':'Стоимость ПО',
        'service':'Обслуживание',
        'orderDemo': 'заказать демо',
        'getDemo': 'получить демо',
        'send': 'Отправить',
        'product': 'продукт',
        'companyName':'Аксиоматика',
        'tariffs':'Тарифы',
        'purchaseService':'По вопросам приобретения ПО просим связаться с нами',
        'company':'Компания',
        'jobs':'Вакансии',
        'contacts':'Контакты',
        'integrations':'Интеграции',
        'developers':'Разработчикам',
        'axiomatika': 'axiomatika',
        'about':'О ',
        'formText': '<b>Система поддержки принятия решений AxiDecision</b> — эффективное промышленное решение!',
        'fullName': 'Имя',
        'email': 'Email',
        'phoneNumber': 'Номер телефона',
        'message': 'Сообщение',
        'documentation': 'Документация',
        'supportService': 'Служба технической поддержки',
        'supportPhoneNumber': 'Телефон',
        'usersGuide': 'Инструкция пользователя',
        'installationInstructions': 'Инструкция по установке',
        'lifeCycleProcessesDescription': 'Описание процессов жизненного цикла',
    }
};

import React, { FC, useEffect, useRef } from 'react';
import Header from './Header';
import Button from './Button';
import Form from './Form';
import Footer from './Footer';

import { FormattedMessage } from 'react-intl';
import '../styles/App.scss';
import translate from '../i18n/translate';

import abstraction from '../assets/icons/symbol.svg';
import { LOCALES } from '../i18n';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import usersGuide from '../assets/documents/Инструкция пользователя.pdf';
import installationInstructions from '../assets/documents/Инструкция по установке AxiDecision Docker.pdf';
import lifeCycleProcessesDescription from '../assets/documents/Описание процессов жизненного цикла.pdf';


interface ILocaleProps {
    name: string;
    locale: string;
}

interface FrontPageProps {
    currentLocale: string;
    languageChange: (lang: string) => void;
}

const FrontPage: FC<FrontPageProps> = ({ currentLocale, languageChange }) => {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!['/ru', '/en'].includes(location.pathname)) {
            navigate('/' + currentLocale);
            return;
        }

        if (params.lang && currentLocale !== params.lang) {
            languageChange(params.lang);
        }
    });

    const languages: ILocaleProps[] = [
        { name: 'Русский', locale: LOCALES.RUSSIAN },
        { name: 'English', locale: LOCALES.ENGLISH }
    ];

    const myRef = useRef<HTMLDivElement>(null);
    const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    };

    const executeScroll = () => {
        scrollToRef(myRef);
    };

    return (
        <div>
            <section className='main_sec'>
                <div className='switcher'>
                    <select value={currentLocale} onChange={(locale) => {
                        languageChange(locale.target.value);
                        navigate('/' + locale.target.value);
                    }}>
                        {languages.map(({ name, locale }) => (
                            <option key={locale} value={locale}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='main_sec-wrapper'>
                    <Header/>
                    <div className='container'>
                        <div className='cover_outside'>
                            <div className='left'>
                                <h1><FormattedMessage id='headerTitle'/></h1>
                                <p className='name'>{translate('axiDecision')}</p>
                                <Button onClick={executeScroll}>{translate('getDemo')}</Button>
                            </div>
                            <div className='right'>
                                <img src={abstraction} alt='abstraction'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='what_is'>
                <div className='container'>
                    <h2>{translate('whatIs')}<b>{translate('axiDecision')}{translate('questionMark')}</b></h2>
                    <p>{translate('axiDecisionDescMain')}</p>
                    <p className='bold'>{translate('manageRequests')}</p>
                    <Button onClick={executeScroll}>{translate('orderDemo')}</Button>
                </div>
            </section>

            <section className='decision'>
                <div className='container'>
                    <p className='top'>{translate('axiDecisionDesc')}</p>
                    <div className='decision__functionality'>
                        <ul className='dashed'>
                            <li>{translate('fn_check')}</li>
                            <li>{translate('fn_gui')}</li>
                            <li>{translate('fn_audit')}</li>
                            <li>{translate('fn_development')}</li>
                            <li>{translate('fn_permissions')}</li>
                            <li>{translate('fn_customization')}</li>
                            <li>{translate('fn_integrity')}</li>
                            <li>{translate('fn_models')}</li>
                        </ul>
                    </div>

                    <p className='top'><b>{translate('advantages')}</b></p>
                    <p className='undertext'>{translate('experience')}</p>
                    <div className='cover_outside'>
                        <div className='info_block'>
                            <p>{translate('lowCost')}</p>
                        </div>
                        <div className='info_block'>
                            <p>{translate('lowCode')}</p>
                        </div>
                        <div className='info_block'>
                            <p>{translate('presets')}</p>
                        </div>
                        <div className='info_block'>
                            <p>{translate('fastIntegration')}</p>
                        </div>
                        <div className='info_block'>
                            <p>{translate('supportCostReduction')}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='doc-title'>{translate('documentation')}</div>
                    <div className='doc-list'>
                        <div className='doc-link'>
                            <a href={usersGuide} target='_blank' rel='noreferrer'>{translate('usersGuide')}</a>
                        </div>
                        <div className='doc-link'>
                            <a href={installationInstructions} target='_blank' rel='noreferrer'>{translate('installationInstructions')}</a>
                        </div>
                        <div className='doc-link'>
                            <a href={lifeCycleProcessesDescription} target='_blank' rel='noreferrer'>{translate('lifeCycleProcessesDescription')}</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='demo'>
                <div className='container demo-prices__title'>{translate('priceTitle')}</div>
                <div className='container _flex _center'>
                    <div>{translate('connectionRequest')}</div>
                    {/*<div className='demo-prices'>*/}
                    {/*<div className='demo-prices__order'>*/}
                    {/*    <span>от 30 000 &#8381;/{translate('month')}</span>*/}
                    {/*</div>*/}
                    {/*<div className='demo-prices__desc'>*/}
                    {/*<span>{translate('connectionCost')}</span>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className='demo-prices'>*/}
                    {/*    <div className='demo-prices__order'>*/}
                    {/*        <span>от 5 000 &#8381;/{translate('month')}</span>*/}
                    {/*    </div>*/}
                    {/*    <div className='demo-prices__desc'>*/}
                    {/*        <span>{translate('service')}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Button onClick={executeScroll}>{translate('getDemo')}</Button>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='support-title'>{translate('purchaseService')}</div>
                    <div className='support-list'>
                        <div className='support__link'>
                            <span className='support__link__label'>{translate('supportPhoneNumber')}:</span>
                            <a className='support__link__content' href='tel:+74953748864'>+7(495)3748864</a>
                        </div>
                        <div className='support-link'>
                            <span className='support__link__label'>Email:</span>
                            <a className='support__link__content'
                                href='mailto:support@axiomatika.ru'>support@axiomatika.ru</a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='support-title'>{translate('supportService')}</div>
                    <div className='support-list'>
                        <div className='support__link'>
                            <span className='support__link__label'>{translate('supportPhoneNumber')}:</span>
                            <a className='support__link__content' href='tel:+74953748864'>+7(495)3748864</a>
                        </div>
                        <div className='support-link'>
                            <span className='support__link__label'>Email:</span>
                            <a className='support__link__content'
                                href='mailto:support@axiomatika.ru'>support@axiomatika.ru</a>
                        </div>
                    </div>
                </div>
            </section>
            <div ref={myRef}>
                <Form/>
            </div>
            <Footer/>
        </div>
    );
};

export default FrontPage;

import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'headerTitle': 'Everything you need to effectively evaluate borrowers',
        'whatIs': 'What is ',
        'axiLink': 'AxiLink',
        'axiDecision': 'Decision support system AxiDecision',
        'axiDecisionDescMain': `The software "AxiDecision Decision Support System" allows you to carry out the development process,
         testing and management of decision-making strategy and business rules.`,
        'axiDecisionDesc': 'Functional characteristics of the software',
        'fn_check': `Creation and modification of logical checks within business processes when assessing creditworthiness, possible fraud,
            validation and underwriting in banking, insurance and other financial institutions;`,
        'fn_gui': 'Supports GUI and pre-installed visual system components for customization and modification capabilities;',
        'fn_audit': 'Auditing and logging user actions;',
        'fn_development': 'Ability to lead development by multiple users;',
        'fn_permissions': 'Implementation of user access control and delineation of physical environments for development, testing and “live” operation;',
        'fn_customization': 'Ability to create your own aggregates, functions and processing scripts through an adaptive language;',
        'fn_integrity': 'Control the integrity of the build and reduce the error rate of system configuration implementation through check-in/check-out mechanism and component versioning;',
        'fn_models': 'Availability of the use of a wide range of scoring models, both interpreted and non/weakly interpreted in PMML format.',
        'manageRequests': `Thanks to its extensive capabilities, the Decision Support System AxiDecision software allows you to transfer all the logic
         to make decisions of the risk manager in the strategy and lay down the algorithm on the basis of which the application processing process is carried out.`,
        'advantages': 'The main advantages of the software "Decision Support System AxiDecision"',
        'experience': `Our team's successful experience in implementing similar credit application evaluation systems from FICO
            and Experian in retail lending for banks, MFIs and insurance companies.`,
        'lowCost': '<b>No</b> capital <b>expenditures</b>;',
        'lowCode': '<b>Low-Code</b> to reduce the amount of time risk department staff are included in the process of developing assessment strategies;',
        'presets': 'Availability of <b>preconfigured, universal objects</b> of the system;',
        'fastIntegration': '<b>High speed</b> implementation;',
        'supportCostReduction': '<b>Reduce the cost</b> of supporting the solution;',
        'questionMark': '?',
        'month': 'month',
        'connectionCost': 'Connection cost',
        'connectionRequest': 'The cost of the software is calculated individually',
        'priceTitle':'The cost of the software',
        'service': 'Service',
        'orderDemo': 'order a demo',
        'getDemo': 'get demo',
        'send': 'Send',
        'product': 'Product',
        'companyName': 'Axiomatika',
        'tariffs': 'Tariffs',
        'purchaseService':'If you have any questions about the purchase, please contact us',
        'company': 'Company',
        'jobs': 'Jobs',
        'contacts': 'Contacts',
        'integrations': 'Integrations',
        'developers': 'Developers',
        'axiomatika': 'axiomatika',
        'about': 'About ',
        'formText': '<b>Decision Support System AxiDecision</b> — an efficient industrial solution!',
        'fullName': 'Full Name',
        'email': 'Email',
        'phoneNumber': 'Phone number',
        'message': 'Message',
        'documentation': 'Documentation',
        'supportService': 'Technical support service',
        'supportPhoneNumber': 'Phone number',
        'usersGuide': 'User Guide',
        'installationInstructions': 'Installation instructions',
        'lifeCycleProcessesDescription': 'Life cycle processes description',
    }
};
